@import url('https://rsms.me/inter/inter.css');
@import url('https://rsms.me/inter/inter-ui.css');

html {
    font-family: 'Nunito Sans', sans-serif;
    scroll-behavior: smooth;
}

@supports (font-variation-settings: normal) {
    html {
        font-family: 'Nunito Sans', sans-serif;
    }
}

@font-face {
    font-family: Gotham;
    src: url('./assets/font/GothamNarrow-Ultra.otf');
}

body,
html,
#root {
    font-family: 'Nunito Sans', sans-serif;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.active {
    color: #bda7a7 !important;
    border-color: #dcd0d0;
}

select {
    min-width: 100px;
}

tbody {
    vertical-align: top;
}

.mn_active {
    border-bottom: 3px solid #3FD3B8;
}

a {
    text-decoration: none;
    color: inherit;
}

p {
    color: inherit;
}

ul,
li {
    list-style: none;
    padding: 0;
    margin: 0;
}

.disabled {
    opacity: 0.4;
}

.li_active {
    color: #1068B4;
}

.odd {
    background: #F8F9FB;
}

.apexcharts-toolbar {
    top: -44px !important;
}

.time_active {
    background: #3FD3B8 !important;
}

.pcactive {
    background: #F7F8F9 !important;
    font-weight: bolder;
}

button:focus,
button:active,
button,
select,
input {
    outline: 0;
}

.apexcharts-menu {
    background: transparent !important;
}

.op {
    opacity: 0.3;
}

.unlockedIcon {
    opacity: 0.3;
}

.apexcharts-xaxis text {
    text-anchor: start
}

input:checked+.slider:before {
    -webkit-transform: translateX(30px);
    -ms-transform: translateX(30px);
    transform: translateX(30px);
}

tr {
    cursor: pointer;
}

.apexcharts-tooltip {
    color: #000;
}

iframe {
    width: 100%;
}
