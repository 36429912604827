@import url(https://rsms.me/inter/inter.css);
@import url(https://rsms.me/inter/inter-ui.css);
.container{
    display: flex;
    justify-content: center;
}
.pagination {
    margin-top: 10px;
    display: flex;
    border-radius: 3px;
    text-transform: uppercase;
    max-width: 450px;
    font-size: 14px;
    /* box-shadow: 0 1px 9px 0 rgba(0,0,0,0.14); */
    align-items:center;
    justify-content:center;
    margin-bottom: 10px;
 }
 .pagination li{
     display: flex;
 }
 .pagination li a{
     display: flex;
     align-items: center;
 }
 .pagination ul a.active,.pagination ul a:active{
     color:#fff !important;
     height: 28px;
     width: 28px;
     border-radius: 4px;
     background-color: #B2B6C3;
     /* box-shadow: inset 0 1px 0 1px rgba(255,255,255,0.09), 0 1px 0 0 rgba(22,29,37,0.1); */
 }
 .pagination ul a{
    text-decoration: none;
    font-weight: 100;
    background: transparent;
    cursor: pointer;
    padding:  3px 8px;
    border-radius: 3px;
}

 .pagination ul.previous i{
    padding-right: 10px;
    font-size: 14px;
 }   
 /* .pagination .previous li:after{
     content: '';
     background: #593442;
     width: 1px;
     height: 17px;
     margin-left: 5px;
     position: relative;
     top: 2px;
     opacity: 0.2;
 } */
 /* .pagination .next li:before{
    content: '';
    background: #593442;
    width: 1px;
    height: 17px;
    margin-right: 5px;
    position: relative;
    top: 2px;
    opacity: 0.2;
} */
 .pagination ul.next i{
    padding-left: 10px;
    font-size: 14px;
 } 
 .pages {
    display:flex;
    flex-direction:row;
 }
 .pages li{
    padding:7px 2px;
 }
 .pagination ul li a span{
    font-weight: bold;
    font-size: 14px;
 }
 
.Terms .lst-kix_list_2-6>li:before {
    content: "\0025aa   "
}

.Terms .lst-kix_list_2-7>li:before {
    content: "\0025aa   "
}

.Terms ul.lst-kix_list_1-0 {
    list-style-type: none
}

.Terms .lst-kix_list_2-4>li:before {
    content: "\0025aa   "
}

.Terms .lst-kix_list_2-5>li:before {
    content: "\0025aa   "
}

.Terms .lst-kix_list_2-8>li:before {
    content: "\0025aa   "
}

.Terms .lst-kix_list_3-0>li:before {
    content: "\0025cf   "
}

.Terms .lst-kix_list_3-1>li:before {
    content: "o  "
}

.Terms .lst-kix_list_3-2>li:before {
    content: "\0025aa   "
}

.Terms ul.lst-kix_list_3-7 {
    list-style-type: none
}

.Terms ul.lst-kix_list_3-8 {
    list-style-type: none
}

.Terms ul.lst-kix_list_1-3 {
    list-style-type: none
}

.Terms ul.lst-kix_list_3-1 {
    list-style-type: none
}

.Terms .lst-kix_list_3-5>li:before {
    content: "\0025aa   "
}

.Terms ul.lst-kix_list_1-4 {
    list-style-type: none
}

.Terms ul.lst-kix_list_3-2 {
    list-style-type: none
}

.Terms ul.lst-kix_list_1-1 {
    list-style-type: none
}

.Terms .lst-kix_list_3-4>li:before {
    content: "\0025aa   "
}

.Terms ul.lst-kix_list_1-2 {
    list-style-type: none
}

.Terms ul.lst-kix_list_3-0 {
    list-style-type: none
}

.Terms ul.lst-kix_list_1-7 {
    list-style-type: none
}

.Terms .lst-kix_list_3-3>li:before {
    content: "\0025aa   "
}

.Terms ul.lst-kix_list_3-5 {
    list-style-type: none
}

.Terms ul.lst-kix_list_1-8 {
    list-style-type: none
}

.Terms ul.lst-kix_list_3-6 {
    list-style-type: none
}

.Terms ul.lst-kix_list_1-5 {
    list-style-type: none
}

.Terms ul.lst-kix_list_3-3 {
    list-style-type: none
}

.Terms ul.lst-kix_list_1-6 {
    list-style-type: none
}

.Terms ul.lst-kix_list_3-4 {
    list-style-type: none
}

.Terms .lst-kix_list_3-8>li:before {
    content: "\0025aa   "
}

.Terms .lst-kix_list_3-6>li:before {
    content: "\0025aa   "
}

.Terms .lst-kix_list_3-7>li:before {
    content: "\0025aa   "
}

.Terms ul.lst-kix_list_2-8 {
    list-style-type: none
}

.Terms ul.lst-kix_list_2-2 {
    list-style-type: none
}

.Terms .lst-kix_list_1-0>li:before {
    content: "\0025cf   "
}

.Terms ul.lst-kix_list_2-3 {
    list-style-type: none
}

.Terms ul.lst-kix_list_2-0 {
    list-style-type: none
}

.Terms ul.lst-kix_list_2-1 {
    list-style-type: none
}

.Terms ul.lst-kix_list_2-6 {
    list-style-type: none
}

.Terms .lst-kix_list_1-1>li:before {
    content: "o  "
}

.Terms .lst-kix_list_1-2>li:before {
    content: "\0025aa   "
}

.Terms ul.lst-kix_list_2-7 {
    list-style-type: none
}

.Terms ul.lst-kix_list_2-4 {
    list-style-type: none
}

.Terms ul.lst-kix_list_2-5 {
    list-style-type: none
}

.Terms .lst-kix_list_1-3>li:before {
    content: "\0025aa   "
}

.Terms .lst-kix_list_1-4>li:before {
    content: "\0025aa   "
}

.Terms .lst-kix_list_1-7>li:before {
    content: "\0025aa   "
}

.Terms .lst-kix_list_1-5>li:before {
    content: "\0025aa   "
}

.Terms .lst-kix_list_1-6>li:before {
    content: "\0025aa   "
}

.Terms .lst-kix_list_2-0>li:before {
    content: "\0025cf   "
}

.Terms .lst-kix_list_2-1>li:before {
    content: "o  "
}

.Terms .lst-kix_list_1-8>li:before {
    content: "\0025aa   "
}

.Terms .lst-kix_list_2-2>li:before {
    content: "\0025aa   "
}

.Terms .lst-kix_list_2-3>li:before {
    content: "\0025aa   "
}

.Terms ol {
    margin: 0;
    padding: 0
}

.Terms table td,
.Terms table th {
    padding: 0
}

.Terms .c30 {
    background-color: #ffffff;
    margin-left: 36pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0791666666666666;
    orphans: 2;
    widows: 2;
    text-align: justify
}

.Terms .c4 {
    background-color: #ffffff;
    margin-left: 36pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 14pt;
    line-height: 1.0791666666666666;
    orphans: 2;
    widows: 2;
    text-align: left
}

.Terms .c9 {
    background-color: #ffffff;
    margin-left: 36pt;
    padding-top: 14pt;
    padding-bottom: 14pt;
    line-height: 1.0791666666666666;
    orphans: 2;
    widows: 2;
    text-align: left;
    height: 11pt
}

.Terms .c2 {
    background-color: #ffffff;
    margin-left: 36pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0791666666666666;
    orphans: 2;
    widows: 2;
    text-align: left
}

.Terms .c29 {
    background-color: #ffffff;
    margin-left: 36pt;
    padding-top: 14pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0791666666666666;
    orphans: 2;
    widows: 2;
    text-align: left
}

.Terms .c8 {
    background-color: #ffffff;
    padding-top: 0pt;
    padding-bottom: 18.8pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
}

.Terms .c5 {
    color: #0b1725;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 18pt;
    font-family: "Tahoma";
    font-style: normal
}

.Terms .c3 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 13.5pt;
    font-family: "Tahoma";
    font-style: normal
}

.Terms .c16 {
    color: #0b1725;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: "Tahoma";
    font-style: normal
}

.Terms .c7 {
    background-color: #ffffff;
    padding-top: 0pt;
    padding-bottom: 18.8pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: center
}

.Terms .c6 {
    background-color: #ffffff;
    padding-top: 0pt;
    padding-bottom: 18.8pt;
    line-height: 1.0791666666666666;
    orphans: 2;
    widows: 2;
    text-align: left
}

.Terms .c17 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: "Helvetica Neue";
    font-style: normal
}

.Terms .c1 {
    background-color: #ffffff;
    padding-top: 0pt;
    padding-bottom: 18.8pt;
    line-height: 1.0791666666666666;
    orphans: 2;
    widows: 2;
    text-align: justify
}

.Terms .c18 {
    background-color: #ffffff;
    padding-top: 0pt;
    padding-bottom: 18.8pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: justify
}

.Terms .c12 {
    color: #0b1725;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 13.5pt;
    font-family: "Tahoma";
    font-style: normal
}

.Terms .c0 {
    color: #0b1725;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: "Helvetica Neue";
    font-style: normal
}

.Terms .c13 {
    padding-top: 0pt;
    padding-bottom: 8pt;
    line-height: 1.0791666666666666;
    orphans: 2;
    widows: 2;
    text-align: left
}

.Terms .c27 {
    color: #000000;
    font-weight: 400;
    font-size: 11pt;
    font-family: "Calibri";
    font-style: normal
}

.Terms .c10 {
    font-size: 12pt;
    font-family: "Arial";
    color: #252525;
    font-weight: 400
}

.Terms .c28 {
    font-weight: 700;
    font-size: 15pt;
    font-family: "Tahoma";
    font-style: normal
}

.Terms .c14 {
    font-size: 12pt;
    font-family: "Helvetica Neue";
    color: #0b1725;
    font-weight: 400
}

.Terms .c15 {
    font-size: 12pt;
    font-family: "Tahoma";
    color: #0b1725;
    font-weight: 700
}

.Terms {
    background-color: #ffffff;
    padding: 5%
}

.Terms .c31 {
    font-family: "Arial";
    color: #252525;
    font-weight: 700
}

.Terms .c11 {
    font-size: 12pt;
    font-family: "Helvetica Neue";
    font-weight: 700
}

.Terms .c22 {
    text-decoration: none;
    vertical-align: baseline
}

.Terms .c23 {
    padding: 0;
    margin: 0
}

.Terms .c25 {
    font-family: "Tahoma";
    font-weight: 400
}

.Terms .c19 {
    height: 11pt
}

.Terms .c20 {
    font-style: italic
}

.Terms .c24 {
    font-size: 12pt
}

.Terms .c21 {
    color: #0b1725
}

.Terms .title {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    font-size: 36pt;
    padding-bottom: 6pt;
    font-family: "Calibri";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.Terms .subtitle {
    padding-top: 18pt;
    color: #666666;
    font-size: 24pt;
    padding-bottom: 4pt;
    font-family: "Georgia";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left
}

.Terms li {
    color: #000000;
    font-size: 11pt;
    font-family: "Calibri"
}

.Terms p {
    margin: 0;
    color: #000000;
    font-size: 11pt;
    font-family: "Calibri"
}

.Terms h1 {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    font-size: 24pt;
    padding-bottom: 6pt;
    font-family: "Calibri";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.Terms h2 {
    padding-top: 0pt;
    color: #000000;
    font-weight: 700;
    font-size: 18pt;
    padding-bottom: 8pt;
    font-family: "Times New Roman";
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
}

.Terms h3 {
    padding-top: 0pt;
    color: #000000;
    font-weight: 700;
    font-size: 13.5pt;
    padding-bottom: 8pt;
    font-family: "Times New Roman";
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
}

.Terms h4 {
    padding-top: 12pt;
    color: #000000;
    font-weight: 700;
    font-size: 12pt;
    padding-bottom: 2pt;
    font-family: "Calibri";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.Terms h5 {
    padding-top: 11pt;
    color: #000000;
    font-weight: 700;
    font-size: 11pt;
    padding-bottom: 2pt;
    font-family: "Calibri";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.Terms h6 {
    padding-top: 10pt;
    color: #000000;
    font-weight: 700;
    font-size: 10pt;
    padding-bottom: 2pt;
    font-family: "Calibri";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.Policy ul.lst-kix_list_1-0 {
    list-style-type: none
}

.Policy .lst-kix_list_3-0>li:before {
    content: "\0025cf   "
}

.Policy ul.lst-kix_list_5-7 {
    list-style-type: none
}

.Policy ul.lst-kix_list_9-3 {
    list-style-type: none
}

.Policy ul.lst-kix_list_5-8 {
    list-style-type: none
}

.Policy ul.lst-kix_list_9-4 {
    list-style-type: none
}

.Policy .lst-kix_list_3-1>li:before {
    content: "\0025cb   "
}

.Policy .lst-kix_list_3-2>li:before {
    content: "\0025a0   "
}

.Policy ul.lst-kix_list_5-5 {
    list-style-type: none
}

.Policy ul.lst-kix_list_9-1 {
    list-style-type: none
}

.Policy ul.lst-kix_list_5-6 {
    list-style-type: none
}

.Policy ul.lst-kix_list_9-2 {
    list-style-type: none
}

.Policy .lst-kix_list_8-1>li:before {
    content: "\0025cb   "
}

.Policy ul.lst-kix_list_9-7 {
    list-style-type: none
}

.Policy ul.lst-kix_list_9-8 {
    list-style-type: none
}

.Policy .lst-kix_list_8-2>li:before {
    content: "\0025a0   "
}

.Policy ul.lst-kix_list_9-5 {
    list-style-type: none
}

.Policy ul.lst-kix_list_9-6 {
    list-style-type: none
}

.Policy ul.lst-kix_list_1-3 {
    list-style-type: none
}

.Policy .lst-kix_list_3-5>li:before {
    content: "\0025a0   "
}

.Policy ul.lst-kix_list_5-0 {
    list-style-type: none
}

.Policy ul.lst-kix_list_1-4 {
    list-style-type: none
}

.Policy ul.lst-kix_list_1-1 {
    list-style-type: none
}

.Policy .lst-kix_list_3-4>li:before {
    content: "\0025cb   "
}

.Policy ul.lst-kix_list_1-2 {
    list-style-type: none
}

.Policy ul.lst-kix_list_5-3 {
    list-style-type: none
}

.Policy ul.lst-kix_list_1-7 {
    list-style-type: none
}

.Policy .lst-kix_list_3-3>li:before {
    content: "\0025cf   "
}

.Policy ul.lst-kix_list_5-4 {
    list-style-type: none
}

.Policy ul.lst-kix_list_9-0 {
    list-style-type: none
}

.Policy ul.lst-kix_list_1-8 {
    list-style-type: none
}

.Policy ul.lst-kix_list_5-1 {
    list-style-type: none
}

.Policy .lst-kix_list_8-0>li:before {
    content: "\0025cf   "
}

.Policy ul.lst-kix_list_1-5 {
    list-style-type: none
}

.Policy ul.lst-kix_list_5-2 {
    list-style-type: none
}

.Policy ul.lst-kix_list_1-6 {
    list-style-type: none
}

.Policy .lst-kix_list_8-7>li:before {
    content: "\0025cb   "
}

.Policy .lst-kix_list_3-8>li:before {
    content: "\0025a0   "
}

.Policy .lst-kix_list_8-5>li:before {
    content: "\0025a0   "
}

.Policy .lst-kix_list_8-6>li:before {
    content: "\0025cf   "
}

.Policy .lst-kix_list_8-3>li:before {
    content: "\0025cf   "
}

.Policy .lst-kix_list_3-6>li:before {
    content: "\0025cf   "
}

.Policy .lst-kix_list_3-7>li:before {
    content: "\0025cb   "
}

.Policy .lst-kix_list_8-4>li:before {
    content: "\0025cb   "
}

.Policy .lst-kix_list_8-8>li:before {
    content: "\0025a0   "
}

.Policy .lst-kix_list_5-0>li:before {
    content: "\0025cf   "
}

.Policy .lst-kix_list_4-8>li:before {
    content: "\0025a0   "
}

.Policy .lst-kix_list_5-3>li:before {
    content: "\0025cf   "
}

.Policy .lst-kix_list_4-7>li:before {
    content: "\0025cb   "
}

.Policy .lst-kix_list_5-2>li:before {
    content: "\0025a0   "
}

.Policy .lst-kix_list_5-1>li:before {
    content: "\0025cb   "
}

.Policy ul.lst-kix_list_4-8 {
    list-style-type: none
}

.Policy .lst-kix_list_5-7>li:before {
    content: "\0025cb   "
}

.Policy ul.lst-kix_list_8-4 {
    list-style-type: none
}

.Policy ul.lst-kix_list_8-5 {
    list-style-type: none
}

.Policy ul.lst-kix_list_4-6 {
    list-style-type: none
}

.Policy .lst-kix_list_5-6>li:before {
    content: "\0025cf   "
}

.Policy .lst-kix_list_5-8>li:before {
    content: "\0025a0   "
}

.Policy ul.lst-kix_list_8-2 {
    list-style-type: none
}

.Policy ul.lst-kix_list_4-7 {
    list-style-type: none
}

.Policy ul.lst-kix_list_8-3 {
    list-style-type: none
}

.Policy ul.lst-kix_list_8-8 {
    list-style-type: none
}

.Policy ul.lst-kix_list_8-6 {
    list-style-type: none
}

.Policy ul.lst-kix_list_8-7 {
    list-style-type: none
}

.Policy ul.lst-kix_list_4-0 {
    list-style-type: none
}

.Policy ul.lst-kix_list_4-1 {
    list-style-type: none
}

.Policy .lst-kix_list_5-4>li:before {
    content: "\0025cb   "
}

.Policy ul.lst-kix_list_4-4 {
    list-style-type: none
}

.Policy .lst-kix_list_5-5>li:before {
    content: "\0025a0   "
}

.Policy ul.lst-kix_list_8-0 {
    list-style-type: none
}

.Policy ul.lst-kix_list_4-5 {
    list-style-type: none
}

.Policy ul.lst-kix_list_8-1 {
    list-style-type: none
}

.Policy ul.lst-kix_list_4-2 {
    list-style-type: none
}

.Policy ul.lst-kix_list_4-3 {
    list-style-type: none
}

.Policy .lst-kix_list_6-1>li:before {
    content: "o  "
}

.Policy .lst-kix_list_6-3>li:before {
    content: "\0025cf   "
}

.Policy .lst-kix_list_6-0>li:before {
    content: "\0025cf   "
}

.Policy .lst-kix_list_6-4>li:before {
    content: "o  "
}

.Policy .lst-kix_list_6-2>li:before {
    content: "\0025aa   "
}

.Policy .lst-kix_list_6-8>li:before {
    content: "\0025aa   "
}

.Policy .lst-kix_list_6-5>li:before {
    content: "\0025aa   "
}

.Policy .lst-kix_list_6-7>li:before {
    content: "o  "
}

.Policy .lst-kix_list_7-0>li:before {
    content: "\0025cf   "
}

.Policy .lst-kix_list_6-6>li:before {
    content: "\0025cf   "
}

.Policy .lst-kix_list_2-6>li:before {
    content: "\0025cf   "
}

.Policy .lst-kix_list_2-7>li:before {
    content: "\0025cb   "
}

.Policy .lst-kix_list_7-4>li:before {
    content: "\0025cb   "
}

.Policy .lst-kix_list_7-6>li:before {
    content: "\0025cf   "
}

.Policy .lst-kix_list_2-4>li:before {
    content: "\0025cb   "
}

.Policy .lst-kix_list_2-5>li:before {
    content: "\0025a0   "
}

.Policy .lst-kix_list_2-8>li:before {
    content: "\0025a0   "
}

.Policy .lst-kix_list_7-1>li:before {
    content: "\0025cb   "
}

.Policy .lst-kix_list_7-5>li:before {
    content: "\0025a0   "
}

.Policy .lst-kix_list_7-2>li:before {
    content: "\0025a0   "
}

.Policy .lst-kix_list_7-3>li:before {
    content: "\0025cf   "
}

.Policy ul.lst-kix_list_7-5 {
    list-style-type: none
}

.Policy ul.lst-kix_list_7-6 {
    list-style-type: none
}

.Policy ul.lst-kix_list_7-3 {
    list-style-type: none
}

.Policy ul.lst-kix_list_3-7 {
    list-style-type: none
}

.Policy ul.lst-kix_list_7-4 {
    list-style-type: none
}

.Policy ul.lst-kix_list_3-8 {
    list-style-type: none
}

.Policy ul.lst-kix_list_7-7 {
    list-style-type: none
}

.Policy ul.lst-kix_list_7-8 {
    list-style-type: none
}

.Policy ul.lst-kix_list_3-1 {
    list-style-type: none
}

.Policy ul.lst-kix_list_3-2 {
    list-style-type: none
}

.Policy .lst-kix_list_7-8>li:before {
    content: "\0025a0   "
}

.Policy ul.lst-kix_list_3-0 {
    list-style-type: none
}

.Policy ul.lst-kix_list_7-1 {
    list-style-type: none
}

.Policy ul.lst-kix_list_3-5 {
    list-style-type: none
}

.Policy ul.lst-kix_list_7-2 {
    list-style-type: none
}

.Policy ul.lst-kix_list_3-6 {
    list-style-type: none
}

.Policy ul.lst-kix_list_3-3 {
    list-style-type: none
}

.Policy ul.lst-kix_list_7-0 {
    list-style-type: none
}

.Policy .lst-kix_list_7-7>li:before {
    content: "\0025cb   "
}

.Policy ul.lst-kix_list_3-4 {
    list-style-type: none
}

.Policy .lst-kix_list_4-0>li:before {
    content: "\0025cf   "
}

.Policy .lst-kix_list_4-1>li:before {
    content: "\0025cb   "
}

.Policy .lst-kix_list_4-4>li:before {
    content: "\0025cb   "
}

.Policy .lst-kix_list_9-2>li:before {
    content: "\0025a0   "
}

.Policy .lst-kix_list_4-3>li:before {
    content: "\0025cf   "
}

.Policy .lst-kix_list_4-5>li:before {
    content: "\0025a0   "
}

.Policy .lst-kix_list_4-2>li:before {
    content: "\0025a0   "
}

.Policy .lst-kix_list_4-6>li:before {
    content: "\0025cf   "
}

.Policy .lst-kix_list_9-3>li:before {
    content: "\0025cf   "
}

.Policy .lst-kix_list_9-0>li:before {
    content: "\0025cf   "
}

.Policy .lst-kix_list_9-1>li:before {
    content: "\0025cb   "
}

.Policy .lst-kix_list_9-7>li:before {
    content: "\0025cb   "
}

.Policy .lst-kix_list_9-6>li:before {
    content: "\0025cf   "
}

.Policy .lst-kix_list_9-4>li:before {
    content: "\0025cb   "
}

.Policy .lst-kix_list_9-5>li:before {
    content: "\0025a0   "
}

.Policy ul.lst-kix_list_6-6 {
    list-style-type: none
}

.Policy ul.lst-kix_list_6-7 {
    list-style-type: none
}

.Policy ul.lst-kix_list_6-4 {
    list-style-type: none
}

.Policy ul.lst-kix_list_2-8 {
    list-style-type: none
}

.Policy ul.lst-kix_list_6-5 {
    list-style-type: none
}

.Policy ul.lst-kix_list_6-8 {
    list-style-type: none
}

.Policy ul.lst-kix_list_2-2 {
    list-style-type: none
}

.Policy .lst-kix_list_1-0>li:before {
    content: "\0025cf   "
}

.Policy ul.lst-kix_list_2-3 {
    list-style-type: none
}

.Policy ul.lst-kix_list_2-0 {
    list-style-type: none
}

.Policy ul.lst-kix_list_2-1 {
    list-style-type: none
}

.Policy ul.lst-kix_list_6-2 {
    list-style-type: none
}

.Policy .lst-kix_list_9-8>li:before {
    content: "\0025a0   "
}

.Policy ul.lst-kix_list_2-6 {
    list-style-type: none
}

.Policy ul.lst-kix_list_6-3 {
    list-style-type: none
}

.Policy .lst-kix_list_1-1>li:before {
    content: "\0025cb   "
}

.Policy .lst-kix_list_1-2>li:before {
    content: "\0025a0   "
}

.Policy ul.lst-kix_list_2-7 {
    list-style-type: none
}

.Policy ul.lst-kix_list_6-0 {
    list-style-type: none
}

.Policy ul.lst-kix_list_2-4 {
    list-style-type: none
}

.Policy ul.lst-kix_list_6-1 {
    list-style-type: none
}

.Policy ul.lst-kix_list_2-5 {
    list-style-type: none
}

.Policy .lst-kix_list_1-3>li:before {
    content: "\0025cf   "
}

.Policy .lst-kix_list_1-4>li:before {
    content: "\0025cb   "
}

.Policy .lst-kix_list_1-7>li:before {
    content: "\0025cb   "
}

.Policy .lst-kix_list_1-5>li:before {
    content: "\0025a0   "
}

.Policy .lst-kix_list_1-6>li:before {
    content: "\0025cf   "
}

.Policy .lst-kix_list_2-0>li:before {
    content: "\0025cf   "
}

.Policy .lst-kix_list_2-1>li:before {
    content: "\0025cb   "
}

.Policy .lst-kix_list_1-8>li:before {
    content: "\0025a0   "
}

.Policy .lst-kix_list_2-2>li:before {
    content: "\0025a0   "
}

.Policy .Policy .lst-kix_list_2-3>li:before {
    content: "\0025cf   "
}

.Policy ol {
    margin: 0;
    padding: 0
}

.Policy table td,
.Policy table th {
    padding: 0
}

.Policy .c20 {
    margin-left: 32.2pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1.75;
    orphans: 2;
    widows: 2;
    text-align: justify
}

.Policy .c3 {
    margin-left: 36pt;
    padding-top: 12pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1.75;
    orphans: 2;
    widows: 2;
    text-align: justify
}

.Policy .c16 {
    margin-left: 35.9pt;
    padding-top: 0pt;
    padding-left: -0.1pt;
    padding-bottom: 0pt;
    line-height: 1.75;
    orphans: 2;
    widows: 2;
    text-align: justify
}

.Policy .c14 {
    margin-left: 35.7pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.75;
    orphans: 2;
    widows: 2;
    text-align: justify;
    height: 11pt
}

.Policy .c19 {
    margin-left: 32.2pt;
    padding-top: 12pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1.75;
    orphans: 2;
    widows: 2;
    text-align: justify
}

.Policy .c5 {
    margin-left: 36pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1.75;
    orphans: 2;
    widows: 2;
    text-align: justify
}

.Policy .c13 {
    padding-top: 0pt;
    padding-bottom: 19pt;
    line-height: 1.1500000000000001;
    orphans: 2;
    widows: 2;
    text-align: justify
}

.Policy .c2 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Arial";
    font-style: normal
}

.Policy .c10 {
    padding-top: 0pt;
    padding-bottom: 19pt;
    line-height: 1.75;
    orphans: 2;
    widows: 2;
    text-align: justify
}

.Policy .c7 {
    color: #0b1725;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 14pt;
    font-family: "Arial";
    font-style: normal
}

.Policy .c1 {
    color: #0b1725;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 13pt;
    font-family: "Arial";
    font-style: normal
}

.Policy .c18 {
    padding-top: 0pt;
    padding-bottom: 19pt;
    line-height: 1.75;
    orphans: 2;
    widows: 2;
    text-align: justify
}

.Policy .c21 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    orphans: 2;
    widows: 2;
    text-align: left
}

.Policy .c23 {
    padding-top: 12pt;
    padding-bottom: 0pt;
    line-height: 1.75;
    orphans: 2;
    widows: 2;
    text-align: justify
}

.Policy .c25 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    orphans: 2;
    widows: 2;
    text-align: justify
}

.Policy .c4 {
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-family: "Arial";
    font-style: normal
}

.Policy {
    background-color: #ffffff;
    padding: 5%;
}

.Policy .c17 {
    margin-left: 36pt;
    padding-left: 0pt
}

.Policy .c26 {
    margin-left: 32.2pt;
    padding-left: 0pt
}

.Policy .c9 {
    color: #0b1725;
    font-size: 18pt
}

.Policy .c15 {
    color: inherit;
    text-decoration: inherit
}

.Policy .c0 {
    color: #0b1725;
    font-size: 12pt
}

.Policy .c6 {
    color: #4b92dd;
    font-size: 12pt
}

.Policy .c8 {
    padding: 0;
    margin: 0
}

.Policy .c11 {
    font-size: 12pt
}

.Policy .c24 {
    height: 11pt
}

.Policy .c12 {
    font-weight: 700
}

.Policy .title {
    padding-top: 0pt;
    color: #000000;
    font-size: 26pt;
    padding-bottom: 3pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.Policy .subtitle {
    padding-top: 0pt;
    color: #666666;
    font-size: 15pt;
    padding-bottom: 16pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.Policy li {
    color: #000000;
    font-size: 11pt;
    font-family: "Arial"
}

.Policy p {
    margin: 0;
    color: #000000;
    font-size: 11pt;
    font-family: "Arial"
}

.Policy h1 {
    padding-top: 20pt;
    color: #000000;
    font-size: 20pt;
    padding-bottom: 6pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.Policy h2 {
    padding-top: 18pt;
    color: #000000;
    font-size: 16pt;
    padding-bottom: 6pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.Policy h3 {
    padding-top: 16pt;
    color: #434343;
    font-size: 14pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.Policy h4 {
    padding-top: 14pt;
    color: #666666;
    font-size: 12pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.Policy h5 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.Policy h6 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left
}

html {
    font-family: 'Nunito Sans', sans-serif;
    scroll-behavior: smooth;
}

@supports (font-variation-settings: normal) {
    html {
        font-family: 'Nunito Sans', sans-serif;
    }
}

@font-face {
    font-family: Gotham;
    src: url(/static/media/GothamNarrow-Ultra.e7fd253c.otf);
}

body,
html,
#root {
    font-family: 'Nunito Sans', sans-serif;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.active {
    color: #bda7a7 !important;
    border-color: #dcd0d0;
}

select {
    min-width: 100px;
}

tbody {
    vertical-align: top;
}

.mn_active {
    border-bottom: 3px solid #3FD3B8;
}

a {
    text-decoration: none;
    color: inherit;
}

p {
    color: inherit;
}

ul,
li {
    list-style: none;
    padding: 0;
    margin: 0;
}

.disabled {
    opacity: 0.4;
}

.li_active {
    color: #1068B4;
}

.odd {
    background: #F8F9FB;
}

.apexcharts-toolbar {
    top: -44px !important;
}

.time_active {
    background: #3FD3B8 !important;
}

.pcactive {
    background: #F7F8F9 !important;
    font-weight: bolder;
}

button:focus,
button:active,
button,
select,
input {
    outline: 0;
}

.apexcharts-menu {
    background: transparent !important;
}

.op {
    opacity: 0.3;
}

.unlockedIcon {
    opacity: 0.3;
}

.apexcharts-xaxis text {
    text-anchor: start
}

input:checked+.slider:before {
    transform: translateX(30px);
}

tr {
    cursor: pointer;
}

.apexcharts-tooltip {
    color: #000;
}

iframe {
    width: 100%;
}

