.Policy ul.lst-kix_list_1-0 {
    list-style-type: none
}

.Policy .lst-kix_list_3-0>li:before {
    content: "\0025cf   "
}

.Policy ul.lst-kix_list_5-7 {
    list-style-type: none
}

.Policy ul.lst-kix_list_9-3 {
    list-style-type: none
}

.Policy ul.lst-kix_list_5-8 {
    list-style-type: none
}

.Policy ul.lst-kix_list_9-4 {
    list-style-type: none
}

.Policy .lst-kix_list_3-1>li:before {
    content: "\0025cb   "
}

.Policy .lst-kix_list_3-2>li:before {
    content: "\0025a0   "
}

.Policy ul.lst-kix_list_5-5 {
    list-style-type: none
}

.Policy ul.lst-kix_list_9-1 {
    list-style-type: none
}

.Policy ul.lst-kix_list_5-6 {
    list-style-type: none
}

.Policy ul.lst-kix_list_9-2 {
    list-style-type: none
}

.Policy .lst-kix_list_8-1>li:before {
    content: "\0025cb   "
}

.Policy ul.lst-kix_list_9-7 {
    list-style-type: none
}

.Policy ul.lst-kix_list_9-8 {
    list-style-type: none
}

.Policy .lst-kix_list_8-2>li:before {
    content: "\0025a0   "
}

.Policy ul.lst-kix_list_9-5 {
    list-style-type: none
}

.Policy ul.lst-kix_list_9-6 {
    list-style-type: none
}

.Policy ul.lst-kix_list_1-3 {
    list-style-type: none
}

.Policy .lst-kix_list_3-5>li:before {
    content: "\0025a0   "
}

.Policy ul.lst-kix_list_5-0 {
    list-style-type: none
}

.Policy ul.lst-kix_list_1-4 {
    list-style-type: none
}

.Policy ul.lst-kix_list_1-1 {
    list-style-type: none
}

.Policy .lst-kix_list_3-4>li:before {
    content: "\0025cb   "
}

.Policy ul.lst-kix_list_1-2 {
    list-style-type: none
}

.Policy ul.lst-kix_list_5-3 {
    list-style-type: none
}

.Policy ul.lst-kix_list_1-7 {
    list-style-type: none
}

.Policy .lst-kix_list_3-3>li:before {
    content: "\0025cf   "
}

.Policy ul.lst-kix_list_5-4 {
    list-style-type: none
}

.Policy ul.lst-kix_list_9-0 {
    list-style-type: none
}

.Policy ul.lst-kix_list_1-8 {
    list-style-type: none
}

.Policy ul.lst-kix_list_5-1 {
    list-style-type: none
}

.Policy .lst-kix_list_8-0>li:before {
    content: "\0025cf   "
}

.Policy ul.lst-kix_list_1-5 {
    list-style-type: none
}

.Policy ul.lst-kix_list_5-2 {
    list-style-type: none
}

.Policy ul.lst-kix_list_1-6 {
    list-style-type: none
}

.Policy .lst-kix_list_8-7>li:before {
    content: "\0025cb   "
}

.Policy .lst-kix_list_3-8>li:before {
    content: "\0025a0   "
}

.Policy .lst-kix_list_8-5>li:before {
    content: "\0025a0   "
}

.Policy .lst-kix_list_8-6>li:before {
    content: "\0025cf   "
}

.Policy .lst-kix_list_8-3>li:before {
    content: "\0025cf   "
}

.Policy .lst-kix_list_3-6>li:before {
    content: "\0025cf   "
}

.Policy .lst-kix_list_3-7>li:before {
    content: "\0025cb   "
}

.Policy .lst-kix_list_8-4>li:before {
    content: "\0025cb   "
}

.Policy .lst-kix_list_8-8>li:before {
    content: "\0025a0   "
}

.Policy .lst-kix_list_5-0>li:before {
    content: "\0025cf   "
}

.Policy .lst-kix_list_4-8>li:before {
    content: "\0025a0   "
}

.Policy .lst-kix_list_5-3>li:before {
    content: "\0025cf   "
}

.Policy .lst-kix_list_4-7>li:before {
    content: "\0025cb   "
}

.Policy .lst-kix_list_5-2>li:before {
    content: "\0025a0   "
}

.Policy .lst-kix_list_5-1>li:before {
    content: "\0025cb   "
}

.Policy ul.lst-kix_list_4-8 {
    list-style-type: none
}

.Policy .lst-kix_list_5-7>li:before {
    content: "\0025cb   "
}

.Policy ul.lst-kix_list_8-4 {
    list-style-type: none
}

.Policy ul.lst-kix_list_8-5 {
    list-style-type: none
}

.Policy ul.lst-kix_list_4-6 {
    list-style-type: none
}

.Policy .lst-kix_list_5-6>li:before {
    content: "\0025cf   "
}

.Policy .lst-kix_list_5-8>li:before {
    content: "\0025a0   "
}

.Policy ul.lst-kix_list_8-2 {
    list-style-type: none
}

.Policy ul.lst-kix_list_4-7 {
    list-style-type: none
}

.Policy ul.lst-kix_list_8-3 {
    list-style-type: none
}

.Policy ul.lst-kix_list_8-8 {
    list-style-type: none
}

.Policy ul.lst-kix_list_8-6 {
    list-style-type: none
}

.Policy ul.lst-kix_list_8-7 {
    list-style-type: none
}

.Policy ul.lst-kix_list_4-0 {
    list-style-type: none
}

.Policy ul.lst-kix_list_4-1 {
    list-style-type: none
}

.Policy .lst-kix_list_5-4>li:before {
    content: "\0025cb   "
}

.Policy ul.lst-kix_list_4-4 {
    list-style-type: none
}

.Policy .lst-kix_list_5-5>li:before {
    content: "\0025a0   "
}

.Policy ul.lst-kix_list_8-0 {
    list-style-type: none
}

.Policy ul.lst-kix_list_4-5 {
    list-style-type: none
}

.Policy ul.lst-kix_list_8-1 {
    list-style-type: none
}

.Policy ul.lst-kix_list_4-2 {
    list-style-type: none
}

.Policy ul.lst-kix_list_4-3 {
    list-style-type: none
}

.Policy .lst-kix_list_6-1>li:before {
    content: "o  "
}

.Policy .lst-kix_list_6-3>li:before {
    content: "\0025cf   "
}

.Policy .lst-kix_list_6-0>li:before {
    content: "\0025cf   "
}

.Policy .lst-kix_list_6-4>li:before {
    content: "o  "
}

.Policy .lst-kix_list_6-2>li:before {
    content: "\0025aa   "
}

.Policy .lst-kix_list_6-8>li:before {
    content: "\0025aa   "
}

.Policy .lst-kix_list_6-5>li:before {
    content: "\0025aa   "
}

.Policy .lst-kix_list_6-7>li:before {
    content: "o  "
}

.Policy .lst-kix_list_7-0>li:before {
    content: "\0025cf   "
}

.Policy .lst-kix_list_6-6>li:before {
    content: "\0025cf   "
}

.Policy .lst-kix_list_2-6>li:before {
    content: "\0025cf   "
}

.Policy .lst-kix_list_2-7>li:before {
    content: "\0025cb   "
}

.Policy .lst-kix_list_7-4>li:before {
    content: "\0025cb   "
}

.Policy .lst-kix_list_7-6>li:before {
    content: "\0025cf   "
}

.Policy .lst-kix_list_2-4>li:before {
    content: "\0025cb   "
}

.Policy .lst-kix_list_2-5>li:before {
    content: "\0025a0   "
}

.Policy .lst-kix_list_2-8>li:before {
    content: "\0025a0   "
}

.Policy .lst-kix_list_7-1>li:before {
    content: "\0025cb   "
}

.Policy .lst-kix_list_7-5>li:before {
    content: "\0025a0   "
}

.Policy .lst-kix_list_7-2>li:before {
    content: "\0025a0   "
}

.Policy .lst-kix_list_7-3>li:before {
    content: "\0025cf   "
}

.Policy ul.lst-kix_list_7-5 {
    list-style-type: none
}

.Policy ul.lst-kix_list_7-6 {
    list-style-type: none
}

.Policy ul.lst-kix_list_7-3 {
    list-style-type: none
}

.Policy ul.lst-kix_list_3-7 {
    list-style-type: none
}

.Policy ul.lst-kix_list_7-4 {
    list-style-type: none
}

.Policy ul.lst-kix_list_3-8 {
    list-style-type: none
}

.Policy ul.lst-kix_list_7-7 {
    list-style-type: none
}

.Policy ul.lst-kix_list_7-8 {
    list-style-type: none
}

.Policy ul.lst-kix_list_3-1 {
    list-style-type: none
}

.Policy ul.lst-kix_list_3-2 {
    list-style-type: none
}

.Policy .lst-kix_list_7-8>li:before {
    content: "\0025a0   "
}

.Policy ul.lst-kix_list_3-0 {
    list-style-type: none
}

.Policy ul.lst-kix_list_7-1 {
    list-style-type: none
}

.Policy ul.lst-kix_list_3-5 {
    list-style-type: none
}

.Policy ul.lst-kix_list_7-2 {
    list-style-type: none
}

.Policy ul.lst-kix_list_3-6 {
    list-style-type: none
}

.Policy ul.lst-kix_list_3-3 {
    list-style-type: none
}

.Policy ul.lst-kix_list_7-0 {
    list-style-type: none
}

.Policy .lst-kix_list_7-7>li:before {
    content: "\0025cb   "
}

.Policy ul.lst-kix_list_3-4 {
    list-style-type: none
}

.Policy .lst-kix_list_4-0>li:before {
    content: "\0025cf   "
}

.Policy .lst-kix_list_4-1>li:before {
    content: "\0025cb   "
}

.Policy .lst-kix_list_4-4>li:before {
    content: "\0025cb   "
}

.Policy .lst-kix_list_9-2>li:before {
    content: "\0025a0   "
}

.Policy .lst-kix_list_4-3>li:before {
    content: "\0025cf   "
}

.Policy .lst-kix_list_4-5>li:before {
    content: "\0025a0   "
}

.Policy .lst-kix_list_4-2>li:before {
    content: "\0025a0   "
}

.Policy .lst-kix_list_4-6>li:before {
    content: "\0025cf   "
}

.Policy .lst-kix_list_9-3>li:before {
    content: "\0025cf   "
}

.Policy .lst-kix_list_9-0>li:before {
    content: "\0025cf   "
}

.Policy .lst-kix_list_9-1>li:before {
    content: "\0025cb   "
}

.Policy .lst-kix_list_9-7>li:before {
    content: "\0025cb   "
}

.Policy .lst-kix_list_9-6>li:before {
    content: "\0025cf   "
}

.Policy .lst-kix_list_9-4>li:before {
    content: "\0025cb   "
}

.Policy .lst-kix_list_9-5>li:before {
    content: "\0025a0   "
}

.Policy ul.lst-kix_list_6-6 {
    list-style-type: none
}

.Policy ul.lst-kix_list_6-7 {
    list-style-type: none
}

.Policy ul.lst-kix_list_6-4 {
    list-style-type: none
}

.Policy ul.lst-kix_list_2-8 {
    list-style-type: none
}

.Policy ul.lst-kix_list_6-5 {
    list-style-type: none
}

.Policy ul.lst-kix_list_6-8 {
    list-style-type: none
}

.Policy ul.lst-kix_list_2-2 {
    list-style-type: none
}

.Policy .lst-kix_list_1-0>li:before {
    content: "\0025cf   "
}

.Policy ul.lst-kix_list_2-3 {
    list-style-type: none
}

.Policy ul.lst-kix_list_2-0 {
    list-style-type: none
}

.Policy ul.lst-kix_list_2-1 {
    list-style-type: none
}

.Policy ul.lst-kix_list_6-2 {
    list-style-type: none
}

.Policy .lst-kix_list_9-8>li:before {
    content: "\0025a0   "
}

.Policy ul.lst-kix_list_2-6 {
    list-style-type: none
}

.Policy ul.lst-kix_list_6-3 {
    list-style-type: none
}

.Policy .lst-kix_list_1-1>li:before {
    content: "\0025cb   "
}

.Policy .lst-kix_list_1-2>li:before {
    content: "\0025a0   "
}

.Policy ul.lst-kix_list_2-7 {
    list-style-type: none
}

.Policy ul.lst-kix_list_6-0 {
    list-style-type: none
}

.Policy ul.lst-kix_list_2-4 {
    list-style-type: none
}

.Policy ul.lst-kix_list_6-1 {
    list-style-type: none
}

.Policy ul.lst-kix_list_2-5 {
    list-style-type: none
}

.Policy .lst-kix_list_1-3>li:before {
    content: "\0025cf   "
}

.Policy .lst-kix_list_1-4>li:before {
    content: "\0025cb   "
}

.Policy .lst-kix_list_1-7>li:before {
    content: "\0025cb   "
}

.Policy .lst-kix_list_1-5>li:before {
    content: "\0025a0   "
}

.Policy .lst-kix_list_1-6>li:before {
    content: "\0025cf   "
}

.Policy .lst-kix_list_2-0>li:before {
    content: "\0025cf   "
}

.Policy .lst-kix_list_2-1>li:before {
    content: "\0025cb   "
}

.Policy .lst-kix_list_1-8>li:before {
    content: "\0025a0   "
}

.Policy .lst-kix_list_2-2>li:before {
    content: "\0025a0   "
}

.Policy .Policy .lst-kix_list_2-3>li:before {
    content: "\0025cf   "
}

.Policy ol {
    margin: 0;
    padding: 0
}

.Policy table td,
.Policy table th {
    padding: 0
}

.Policy .c20 {
    margin-left: 32.2pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1.75;
    orphans: 2;
    widows: 2;
    text-align: justify
}

.Policy .c3 {
    margin-left: 36pt;
    padding-top: 12pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1.75;
    orphans: 2;
    widows: 2;
    text-align: justify
}

.Policy .c16 {
    margin-left: 35.9pt;
    padding-top: 0pt;
    padding-left: -0.1pt;
    padding-bottom: 0pt;
    line-height: 1.75;
    orphans: 2;
    widows: 2;
    text-align: justify
}

.Policy .c14 {
    margin-left: 35.7pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.75;
    orphans: 2;
    widows: 2;
    text-align: justify;
    height: 11pt
}

.Policy .c19 {
    margin-left: 32.2pt;
    padding-top: 12pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1.75;
    orphans: 2;
    widows: 2;
    text-align: justify
}

.Policy .c5 {
    margin-left: 36pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1.75;
    orphans: 2;
    widows: 2;
    text-align: justify
}

.Policy .c13 {
    padding-top: 0pt;
    padding-bottom: 19pt;
    line-height: 1.1500000000000001;
    orphans: 2;
    widows: 2;
    text-align: justify
}

.Policy .c2 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Arial";
    font-style: normal
}

.Policy .c10 {
    padding-top: 0pt;
    padding-bottom: 19pt;
    line-height: 1.75;
    orphans: 2;
    widows: 2;
    text-align: justify
}

.Policy .c7 {
    color: #0b1725;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 14pt;
    font-family: "Arial";
    font-style: normal
}

.Policy .c1 {
    color: #0b1725;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 13pt;
    font-family: "Arial";
    font-style: normal
}

.Policy .c18 {
    padding-top: 0pt;
    padding-bottom: 19pt;
    line-height: 1.75;
    orphans: 2;
    widows: 2;
    text-align: justify
}

.Policy .c21 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    orphans: 2;
    widows: 2;
    text-align: left
}

.Policy .c23 {
    padding-top: 12pt;
    padding-bottom: 0pt;
    line-height: 1.75;
    orphans: 2;
    widows: 2;
    text-align: justify
}

.Policy .c25 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    orphans: 2;
    widows: 2;
    text-align: justify
}

.Policy .c4 {
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-family: "Arial";
    font-style: normal
}

.Policy {
    background-color: #ffffff;
    padding: 5%;
}

.Policy .c17 {
    margin-left: 36pt;
    padding-left: 0pt
}

.Policy .c26 {
    margin-left: 32.2pt;
    padding-left: 0pt
}

.Policy .c9 {
    color: #0b1725;
    font-size: 18pt
}

.Policy .c15 {
    color: inherit;
    text-decoration: inherit
}

.Policy .c0 {
    color: #0b1725;
    font-size: 12pt
}

.Policy .c6 {
    color: #4b92dd;
    font-size: 12pt
}

.Policy .c8 {
    padding: 0;
    margin: 0
}

.Policy .c11 {
    font-size: 12pt
}

.Policy .c24 {
    height: 11pt
}

.Policy .c12 {
    font-weight: 700
}

.Policy .title {
    padding-top: 0pt;
    color: #000000;
    font-size: 26pt;
    padding-bottom: 3pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.Policy .subtitle {
    padding-top: 0pt;
    color: #666666;
    font-size: 15pt;
    padding-bottom: 16pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.Policy li {
    color: #000000;
    font-size: 11pt;
    font-family: "Arial"
}

.Policy p {
    margin: 0;
    color: #000000;
    font-size: 11pt;
    font-family: "Arial"
}

.Policy h1 {
    padding-top: 20pt;
    color: #000000;
    font-size: 20pt;
    padding-bottom: 6pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.Policy h2 {
    padding-top: 18pt;
    color: #000000;
    font-size: 16pt;
    padding-bottom: 6pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.Policy h3 {
    padding-top: 16pt;
    color: #434343;
    font-size: 14pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.Policy h4 {
    padding-top: 14pt;
    color: #666666;
    font-size: 12pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.Policy h5 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.Policy h6 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left
}
