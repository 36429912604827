.Terms .lst-kix_list_2-6>li:before {
    content: "\0025aa   "
}

.Terms .lst-kix_list_2-7>li:before {
    content: "\0025aa   "
}

.Terms ul.lst-kix_list_1-0 {
    list-style-type: none
}

.Terms .lst-kix_list_2-4>li:before {
    content: "\0025aa   "
}

.Terms .lst-kix_list_2-5>li:before {
    content: "\0025aa   "
}

.Terms .lst-kix_list_2-8>li:before {
    content: "\0025aa   "
}

.Terms .lst-kix_list_3-0>li:before {
    content: "\0025cf   "
}

.Terms .lst-kix_list_3-1>li:before {
    content: "o  "
}

.Terms .lst-kix_list_3-2>li:before {
    content: "\0025aa   "
}

.Terms ul.lst-kix_list_3-7 {
    list-style-type: none
}

.Terms ul.lst-kix_list_3-8 {
    list-style-type: none
}

.Terms ul.lst-kix_list_1-3 {
    list-style-type: none
}

.Terms ul.lst-kix_list_3-1 {
    list-style-type: none
}

.Terms .lst-kix_list_3-5>li:before {
    content: "\0025aa   "
}

.Terms ul.lst-kix_list_1-4 {
    list-style-type: none
}

.Terms ul.lst-kix_list_3-2 {
    list-style-type: none
}

.Terms ul.lst-kix_list_1-1 {
    list-style-type: none
}

.Terms .lst-kix_list_3-4>li:before {
    content: "\0025aa   "
}

.Terms ul.lst-kix_list_1-2 {
    list-style-type: none
}

.Terms ul.lst-kix_list_3-0 {
    list-style-type: none
}

.Terms ul.lst-kix_list_1-7 {
    list-style-type: none
}

.Terms .lst-kix_list_3-3>li:before {
    content: "\0025aa   "
}

.Terms ul.lst-kix_list_3-5 {
    list-style-type: none
}

.Terms ul.lst-kix_list_1-8 {
    list-style-type: none
}

.Terms ul.lst-kix_list_3-6 {
    list-style-type: none
}

.Terms ul.lst-kix_list_1-5 {
    list-style-type: none
}

.Terms ul.lst-kix_list_3-3 {
    list-style-type: none
}

.Terms ul.lst-kix_list_1-6 {
    list-style-type: none
}

.Terms ul.lst-kix_list_3-4 {
    list-style-type: none
}

.Terms .lst-kix_list_3-8>li:before {
    content: "\0025aa   "
}

.Terms .lst-kix_list_3-6>li:before {
    content: "\0025aa   "
}

.Terms .lst-kix_list_3-7>li:before {
    content: "\0025aa   "
}

.Terms ul.lst-kix_list_2-8 {
    list-style-type: none
}

.Terms ul.lst-kix_list_2-2 {
    list-style-type: none
}

.Terms .lst-kix_list_1-0>li:before {
    content: "\0025cf   "
}

.Terms ul.lst-kix_list_2-3 {
    list-style-type: none
}

.Terms ul.lst-kix_list_2-0 {
    list-style-type: none
}

.Terms ul.lst-kix_list_2-1 {
    list-style-type: none
}

.Terms ul.lst-kix_list_2-6 {
    list-style-type: none
}

.Terms .lst-kix_list_1-1>li:before {
    content: "o  "
}

.Terms .lst-kix_list_1-2>li:before {
    content: "\0025aa   "
}

.Terms ul.lst-kix_list_2-7 {
    list-style-type: none
}

.Terms ul.lst-kix_list_2-4 {
    list-style-type: none
}

.Terms ul.lst-kix_list_2-5 {
    list-style-type: none
}

.Terms .lst-kix_list_1-3>li:before {
    content: "\0025aa   "
}

.Terms .lst-kix_list_1-4>li:before {
    content: "\0025aa   "
}

.Terms .lst-kix_list_1-7>li:before {
    content: "\0025aa   "
}

.Terms .lst-kix_list_1-5>li:before {
    content: "\0025aa   "
}

.Terms .lst-kix_list_1-6>li:before {
    content: "\0025aa   "
}

.Terms .lst-kix_list_2-0>li:before {
    content: "\0025cf   "
}

.Terms .lst-kix_list_2-1>li:before {
    content: "o  "
}

.Terms .lst-kix_list_1-8>li:before {
    content: "\0025aa   "
}

.Terms .lst-kix_list_2-2>li:before {
    content: "\0025aa   "
}

.Terms .lst-kix_list_2-3>li:before {
    content: "\0025aa   "
}

.Terms ol {
    margin: 0;
    padding: 0
}

.Terms table td,
.Terms table th {
    padding: 0
}

.Terms .c30 {
    background-color: #ffffff;
    margin-left: 36pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0791666666666666;
    orphans: 2;
    widows: 2;
    text-align: justify
}

.Terms .c4 {
    background-color: #ffffff;
    margin-left: 36pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 14pt;
    line-height: 1.0791666666666666;
    orphans: 2;
    widows: 2;
    text-align: left
}

.Terms .c9 {
    background-color: #ffffff;
    margin-left: 36pt;
    padding-top: 14pt;
    padding-bottom: 14pt;
    line-height: 1.0791666666666666;
    orphans: 2;
    widows: 2;
    text-align: left;
    height: 11pt
}

.Terms .c2 {
    background-color: #ffffff;
    margin-left: 36pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0791666666666666;
    orphans: 2;
    widows: 2;
    text-align: left
}

.Terms .c29 {
    background-color: #ffffff;
    margin-left: 36pt;
    padding-top: 14pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0791666666666666;
    orphans: 2;
    widows: 2;
    text-align: left
}

.Terms .c8 {
    background-color: #ffffff;
    padding-top: 0pt;
    padding-bottom: 18.8pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
}

.Terms .c5 {
    color: #0b1725;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 18pt;
    font-family: "Tahoma";
    font-style: normal
}

.Terms .c3 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 13.5pt;
    font-family: "Tahoma";
    font-style: normal
}

.Terms .c16 {
    color: #0b1725;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: "Tahoma";
    font-style: normal
}

.Terms .c7 {
    background-color: #ffffff;
    padding-top: 0pt;
    padding-bottom: 18.8pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: center
}

.Terms .c6 {
    background-color: #ffffff;
    padding-top: 0pt;
    padding-bottom: 18.8pt;
    line-height: 1.0791666666666666;
    orphans: 2;
    widows: 2;
    text-align: left
}

.Terms .c17 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: "Helvetica Neue";
    font-style: normal
}

.Terms .c1 {
    background-color: #ffffff;
    padding-top: 0pt;
    padding-bottom: 18.8pt;
    line-height: 1.0791666666666666;
    orphans: 2;
    widows: 2;
    text-align: justify
}

.Terms .c18 {
    background-color: #ffffff;
    padding-top: 0pt;
    padding-bottom: 18.8pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: justify
}

.Terms .c12 {
    color: #0b1725;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 13.5pt;
    font-family: "Tahoma";
    font-style: normal
}

.Terms .c0 {
    color: #0b1725;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: "Helvetica Neue";
    font-style: normal
}

.Terms .c13 {
    padding-top: 0pt;
    padding-bottom: 8pt;
    line-height: 1.0791666666666666;
    orphans: 2;
    widows: 2;
    text-align: left
}

.Terms .c27 {
    color: #000000;
    font-weight: 400;
    font-size: 11pt;
    font-family: "Calibri";
    font-style: normal
}

.Terms .c10 {
    font-size: 12pt;
    font-family: "Arial";
    color: #252525;
    font-weight: 400
}

.Terms .c28 {
    font-weight: 700;
    font-size: 15pt;
    font-family: "Tahoma";
    font-style: normal
}

.Terms .c14 {
    font-size: 12pt;
    font-family: "Helvetica Neue";
    color: #0b1725;
    font-weight: 400
}

.Terms .c15 {
    font-size: 12pt;
    font-family: "Tahoma";
    color: #0b1725;
    font-weight: 700
}

.Terms {
    background-color: #ffffff;
    padding: 5%
}

.Terms .c31 {
    font-family: "Arial";
    color: #252525;
    font-weight: 700
}

.Terms .c11 {
    font-size: 12pt;
    font-family: "Helvetica Neue";
    font-weight: 700
}

.Terms .c22 {
    text-decoration: none;
    vertical-align: baseline
}

.Terms .c23 {
    padding: 0;
    margin: 0
}

.Terms .c25 {
    font-family: "Tahoma";
    font-weight: 400
}

.Terms .c19 {
    height: 11pt
}

.Terms .c20 {
    font-style: italic
}

.Terms .c24 {
    font-size: 12pt
}

.Terms .c21 {
    color: #0b1725
}

.Terms .title {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    font-size: 36pt;
    padding-bottom: 6pt;
    font-family: "Calibri";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.Terms .subtitle {
    padding-top: 18pt;
    color: #666666;
    font-size: 24pt;
    padding-bottom: 4pt;
    font-family: "Georgia";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left
}

.Terms li {
    color: #000000;
    font-size: 11pt;
    font-family: "Calibri"
}

.Terms p {
    margin: 0;
    color: #000000;
    font-size: 11pt;
    font-family: "Calibri"
}

.Terms h1 {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    font-size: 24pt;
    padding-bottom: 6pt;
    font-family: "Calibri";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.Terms h2 {
    padding-top: 0pt;
    color: #000000;
    font-weight: 700;
    font-size: 18pt;
    padding-bottom: 8pt;
    font-family: "Times New Roman";
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
}

.Terms h3 {
    padding-top: 0pt;
    color: #000000;
    font-weight: 700;
    font-size: 13.5pt;
    padding-bottom: 8pt;
    font-family: "Times New Roman";
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
}

.Terms h4 {
    padding-top: 12pt;
    color: #000000;
    font-weight: 700;
    font-size: 12pt;
    padding-bottom: 2pt;
    font-family: "Calibri";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.Terms h5 {
    padding-top: 11pt;
    color: #000000;
    font-weight: 700;
    font-size: 11pt;
    padding-bottom: 2pt;
    font-family: "Calibri";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.Terms h6 {
    padding-top: 10pt;
    color: #000000;
    font-weight: 700;
    font-size: 10pt;
    padding-bottom: 2pt;
    font-family: "Calibri";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}
