.container{
    display: flex;
    justify-content: center;
}
.pagination {
    margin-top: 10px;
    display: flex;
    border-radius: 3px;
    text-transform: uppercase;
    max-width: 450px;
    font-size: 14px;
    /* box-shadow: 0 1px 9px 0 rgba(0,0,0,0.14); */
    align-items:center;
    justify-content:center;
    margin-bottom: 10px;
 }
 .pagination li{
     display: flex;
 }
 .pagination li a{
     display: flex;
     align-items: center;
 }
 .pagination ul a.active,.pagination ul a:active{
     color:#fff !important;
     height: 28px;
     width: 28px;
     border-radius: 4px;
     background-color: #B2B6C3;
     /* box-shadow: inset 0 1px 0 1px rgba(255,255,255,0.09), 0 1px 0 0 rgba(22,29,37,0.1); */
 }
 .pagination ul a{
    text-decoration: none;
    font-weight: 100;
    background: transparent;
    cursor: pointer;
    padding:  3px 8px;
    border-radius: 3px;
}

 .pagination ul.previous i{
    padding-right: 10px;
    font-size: 14px;
 }   
 /* .pagination .previous li:after{
     content: '';
     background: #593442;
     width: 1px;
     height: 17px;
     margin-left: 5px;
     position: relative;
     top: 2px;
     opacity: 0.2;
 } */
 /* .pagination .next li:before{
    content: '';
    background: #593442;
    width: 1px;
    height: 17px;
    margin-right: 5px;
    position: relative;
    top: 2px;
    opacity: 0.2;
} */
 .pagination ul.next i{
    padding-left: 10px;
    font-size: 14px;
 } 
 .pages {
    display:flex;
    flex-direction:row;
 }
 .pages li{
    padding:7px 2px;
 }
 .pagination ul li a span{
    font-weight: bold;
    font-size: 14px;
 }
 